import React, { useEffect, useState } from "react";
import style from "styled-components";
import client from "./contentful";

const AboutContainer = style.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: #222;
    padding-top: 5vh;
    padding-bottom: 5vh;

    @media (max-width: 768px) {
        height: auto;
        
    }
`;

const AboutContent = style.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    gap: 2rem;

    @media (max-width: 768px) {
        padding-top: 10vh;
        padding-bottom: 10vh;
        flex-direction: column-reverse;
    }
`;

const AboutParagraph = style.p`
    font-family: 'Montserrat', sans-serif;
    max-width: 40%;
    font-size: 1rem;
    color: white;
    text-align: left;
    margin-right: 3rem;
    line-height: 1.5;

    &:hover {
        transform: scale(1.1);
        transition: transform 0.5s;
    }

    @media (max-width: 768px) {
        max-width: 70%;
        font-size: 1.2rem;
        text-align: center;
        margin-right: 0;
    }

`;

const AboutImage = style.img`
    height: 15rem;
    width: 15rem;
    border: 12px solid white;

    
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);

    @media (max-width: 768px) {
        margin-bottom: 10vh;
`;

function About() {
  const [images, setImages] = useState([]);
  const [aboutText, setAboutText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch profile images (existing logic)
        const imageResponse = await client.getEntries({
          content_type: "profileImage",
        });
        const imageUrls = imageResponse.items
          .map((item) => item.fields.profileImage.fields.file.url)
          .filter((url) => url !== null);
        setImages(imageUrls);

        // Fetch AboutParagraph text content
        const textResponse = await client.getEntries({
          content_type: "aboutParagraph",
        });
        if (textResponse.items && textResponse.items.length > 0) {
          const text = textResponse.items[0].fields.aboutParagraph; // Assuming "text" field in AboutParagraph
          setAboutText(text);
        } else {
          console.error("No aboutParagraph text found in Contentful response.");
        }
      } catch (error) {
        console.error("Error fetching entries:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <AboutContainer>
      <AboutContent>
        <AboutParagraph>
          {aboutText ? aboutText : "Loading About Text..."}
        </AboutParagraph>
        <div>
          {images.map((image, index) => (
            <AboutImage
              key={index}
              src={image}
              alt={`Profile Image ${index + 1}`}
            />
          ))}
        </div>
      </AboutContent>
    </AboutContainer>
  );
}

export default About;
