export const activities = [
  {
    id: "activity-1",
    name: "Gym (Strength & Conditioning)",
    type: "Gym",
    color: "#FFA07A",
    maxPerWeek: 3,
    duration: 1.5, // 1.5 hours
  },
  {
    id: "activity-2",
    name: "Gym (Mobility & Flexibility)",
    type: "Gym",
    color: "#98FB98",
    maxPerWeek: 3,
    duration: 1, // 1 hour
  },
  {
    id: "activity-3",
    name: "Long Game Practice",
    type: "Long Game",
    color: "#87CEFA",
    maxPerWeek: 3,
    duration: 1, // 2 hours
  },
  {
    id: "activity-4",
    name: "Short Game Practice",
    type: "Short Game",
    color: "#DDA0DD",
    maxPerWeek: 4,
    duration: 1.5, // 1.5 hours
  },
  {
    id: "activity-5",
    name: "Putting Practice",
    type: "Putting",
    color: "#F0E68C",
    maxPerWeek: 4,
    duration: 1.5, // 1 hour
  },
  {
    id: "activity-6",
    name: "9-Hole Play",
    type: "Play",
    color: "#20B2AA",
    maxPerWeek: 3,
    duration: 2, // 2 hours
  },
  {
    id: "activity-7",
    name: "18-Hole Play",
    type: "Play",
    color: "#FF6347",
    maxPerWeek: 1,
    duration: 4, // 4 hours
  },
  {
    id: "activity-8",
    name: "Trackman Practice",
    type: "Trackman",
    color: "#4682B4",
    maxPerWeek: 1,
    duration: 1, // 1 hour
  },
  {
    id: "activity-9",
    name: "Lesson",
    type: "Lesson",
    color: "#D2691E",
    maxPerWeek: 1,
    duration: 1, // 1 hour
  },
  {
    id: "activity-10",
    name: "Rest/Active Recovery",
    type: "Rest",
    color: "#708090",
    maxPerWeek: 7,
    duration: 1, // 1 hour (adjustable)
  },
];
