import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDOyDWvDil9cp9hjwRGqSCa3YOYlUSxF4c",
  authDomain: "camgolf.firebaseapp.com",
  projectId: "camgolf",
  storageBucket: "camgolf.appspot.com",
  messagingSenderId: "834484553859",
  appId: "1:834484553859:web:ad5f790c6a82af15b6f3b6",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// Add any additional scopes you need, e.g., for Google Calendar
googleProvider.addScope("https://www.googleapis.com/auth/calendar");

// In firebase.js
export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/calendar.events");
  try {
    const result = await signInWithPopup(auth, provider);
    return result.user;
  } catch (error) {
    console.error("Error signing in with Google", error);
    throw error;
  }
};

export { app, db, auth, googleProvider };
