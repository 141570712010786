import React, { useState } from "react";
import styled from "styled-components";
import useScores from "../hooks/useScores";
import useHandicapData from "../hooks/useHandicapData";
import TrainingCalendar from "./TrainingCalendar";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Input = styled.input`
  margin: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  max-width: 50px;

  @media (max-width: 768px) {
    max-width: 40px;
    padding: 1px;
    font-size: 12px;
    margin: 0px;
  }
`;

const TextInput = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  max-width: auto;
  margin: 10px 0px;

  @media (max-width: 768px) {
    max-width: 90vw;
    padding: 10px;
    font-size: 12px;
    margin: 10px 0px;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    padding: 5px 10px;
    font-size: 12px;
  }
`;

const TableContainer = styled.div`
  width: 90vw;
  max-width: 100%;
  margin: 20px 0;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
  }

  @media (max-width: 768px) {
    th,
    td {
      font-size: 10px;
      padding: 4px;
    }
  }
`;

const AdminDashboard = ({ setIsAuthenticated }) => {
  const { scores, addScore, deleteScore, updateScore } = useScores();
  const [editingId, setEditingId] = useState(null);
  const [holeScores, setHoleScores] = useState(Array(18).fill(""));
  const [holePars, setHolePars] = useState(Array(18).fill(""));
  const [gir, setGIR] = useState(Array(18).fill(false));
  const [fir, setFIR] = useState(Array(18).fill(false));
  const [driveDistance, setDriveDistance] = useState(Array(18).fill(""));
  const [putts, setPutts] = useState(Array(18).fill(""));
  const [proximityToHole, setProximityToHole] = useState(Array(18).fill(""));
  const [courseName, setCourseName] = useState("");
  const [eventName, setEventName] = useState("");
  const [date, setDate] = useState("");
  const { addHandicapData } = useHandicapData();
  const [handicapIndex, setHandicapIndex] = useState("");
  const [scoreDifferential, setScoreDifferential] = useState("");

  const handleHoleScoreChange = (index, value) => {
    const updatedHoleScores = [...holeScores];
    updatedHoleScores[index] = value;
    setHoleScores(updatedHoleScores);
  };

  const handleHoleParChange = (index, value) => {
    const updatedHolePars = [...holePars];
    updatedHolePars[index] = value;
    setHolePars(updatedHolePars);
  };

  const handleGIRChange = (index) => {
    const updatedGIR = [...gir];
    updatedGIR[index] = !updatedGIR[index];
    setGIR(updatedGIR);
  };

  const handleFIRChange = (index) => {
    const updatedFIR = [...fir];
    updatedFIR[index] = !updatedFIR[index];
    setFIR(updatedFIR);
  };

  const handleDriveDistanceChange = (index, value) => {
    const updatedDriveDistance = [...driveDistance];
    updatedDriveDistance[index] = value;
    setDriveDistance(updatedDriveDistance);
  };

  const handlePuttsChange = (index, value) => {
    const updatedPutts = [...putts];
    updatedPutts[index] = value;
    setPutts(updatedPutts);
  };

  const handleProximityChange = (index, value) => {
    const updatedProximity = [...proximityToHole];
    updatedProximity[index] = value;
    setProximityToHole(updatedProximity);
  };

  const calculateTotalScore = () => {
    return holeScores.reduce(
      (total, score) => total + (parseInt(score) || 0),
      0
    );
  };

  const handleHandicapSubmit = async (e) => {
    e.preventDefault();
    const newHandicapData = {
      handicapIndex: parseFloat(handicapIndex),
      scoreDifferential: parseFloat(scoreDifferential),
      date: new Date().toISOString(),
    };
    try {
      await addHandicapData(newHandicapData);
      setHandicapIndex("");
      setScoreDifferential("");
      // Optionally, add a success message here
      console.log("Handicap data added successfully");
    } catch (error) {
      console.error("Error adding handicap data:", error);
      // Optionally, display an error message to the user
      alert("Failed to add handicap data. Please try again.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newScore = {
      totalScore: calculateTotalScore(),
      holeScores: holeScores.map(Number),
      holePars: holePars.map(Number),
      gir,
      fir,
      driveDistance: driveDistance.map(Number),
      putts: putts.map(Number),
      proximityToHole: proximityToHole.map(Number),
      courseName,
      eventName,
      date,
    };
    try {
      await addScore(newScore);
      setHoleScores(Array(18).fill(""));
      setHolePars(Array(18).fill(""));
      setGIR(Array(18).fill(false));
      setFIR(Array(18).fill(false));
      setDriveDistance(Array(18).fill(""));
      setPutts(Array(18).fill(""));
      setProximityToHole(Array(18).fill(""));
      setCourseName("");
      setEventName("");
      setDate("");
    } catch (error) {
      console.error("Error adding score:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteScore(id);
    } catch (error) {
      console.error("Error deleting score:", error);
    }
  };

  const handleEdit = (score) => {
    setEditingId(score.id);
    setHoleScores(score.holeScores);
    setHolePars(score.holePars);
    setGIR(score.gir);
    setFIR(score.fir);
    setDriveDistance(score.driveDistance);
    setPutts(score.putts);
    setProximityToHole(score.proximityToHole);
    setCourseName(score.courseName);
    setEventName(score.eventName);
    setDate(score.date);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const updatedScore = {
      totalScore: calculateTotalScore(),
      holeScores: holeScores.map(Number),
      holePars: holePars.map(Number),
      gir,
      fir,
      driveDistance: driveDistance.map(Number),
      putts: putts.map(Number),
      proximityToHole: proximityToHole.map(Number),
      courseName,
      eventName,
      date,
    };
    try {
      await updateScore(editingId, updatedScore);
      setEditingId(null);
      // Reset form fields
      setHoleScores(Array(18).fill(""));
      setHolePars(Array(18).fill(""));
      setGIR(Array(18).fill(false));
      setFIR(Array(18).fill(false));
      setDriveDistance(Array(18).fill(""));
      setPutts(Array(18).fill(""));
      setProximityToHole(Array(18).fill(""));
      setCourseName("");
      setEventName("");
      setDate("");
    } catch (error) {
      console.error("Error updating score:", error);
    }
  };

  const [user, setUser] = useState(null);

  const handleGoogleLoginSuccess = (credentialResponse) => {
    console.log(credentialResponse);
    setUser(credentialResponse);
    setIsAuthenticated(true);
  };

  const handleGoogleLoginFailure = () => {
    console.log("Login Failed");
  };

  return (
    <DashboardContainer>
      <h2>Admin Dashboard</h2>

      {!user ? (
        <GoogleOAuthProvider clientId="834484553859-ejsu5monginkg32cmavvqouaehdtgbt1.apps.googleusercontent.com">
          <GoogleLogin
            onSuccess={handleGoogleLoginSuccess}
            onError={handleGoogleLoginFailure}
          />
        </GoogleOAuthProvider>
      ) : (
        <>
          <h3>Add Handicap Data</h3>
          <form onSubmit={handleHandicapSubmit}>
            <TextInput
              type="number"
              step="0.1"
              placeholder="Handicap Index"
              value={handicapIndex}
              onChange={(e) => setHandicapIndex(e.target.value)}
            />
            <TextInput
              type="number"
              step="0.1"
              placeholder="Score Differential"
              value={scoreDifferential}
              onChange={(e) => setScoreDifferential(e.target.value)}
            />
            <Button type="submit">Add Handicap Data</Button>
          </form>

          <form onSubmit={editingId ? handleUpdate : handleSubmit}>
            <TextInput
              type="text"
              placeholder="Course Name"
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
            />
            <TextInput
              type="text"
              placeholder="Event Name"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
            />
            <TextInput
              type="date"
              placeholder="Date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
            <TableContainer>
              <Table>
                <thead>
                  <tr>
                    <th colSpan="9">Hole Number</th>
                  </tr>
                  <tr>
                    {Array.from({ length: 9 }, (_, i) => (
                      <th key={i}>{i + 1}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{ background: "darkgray", color: "white" }}
                      colSpan="9"
                    >
                      Hole Pars
                    </td>
                  </tr>
                  <tr>
                    {holePars.slice(0, 9).map((par, index) => (
                      <td key={`par-${index}`}>
                        <Input
                          type="number"
                          value={par}
                          onChange={(e) =>
                            handleHoleParChange(index, e.target.value)
                          }
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td
                      style={{ background: "darkgray", color: "white" }}
                      colSpan="9"
                    >
                      Hole Scores
                    </td>
                  </tr>
                  <tr>
                    {holeScores.slice(0, 9).map((score, index) => (
                      <td key={`score-${index}`}>
                        <Input
                          type="number"
                          value={score}
                          onChange={(e) =>
                            handleHoleScoreChange(index, e.target.value)
                          }
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td
                      style={{ background: "darkgray", color: "white" }}
                      colSpan="9"
                    >
                      GIR
                    </td>
                  </tr>
                  <tr>
                    {gir.slice(0, 9).map((value, index) => (
                      <td key={`gir-${index}`}>
                        <Input
                          type="checkbox"
                          checked={value}
                          onChange={() => handleGIRChange(index)}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td
                      style={{ background: "darkgray", color: "white" }}
                      colSpan="9"
                    >
                      FIR
                    </td>
                  </tr>
                  <tr>
                    {fir.slice(0, 9).map((value, index) => (
                      <td key={`fir-${index}`}>
                        <Input
                          type="checkbox"
                          checked={value}
                          onChange={() => handleFIRChange(index)}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td
                      style={{ background: "darkgray", color: "white" }}
                      colSpan="9"
                    >
                      Drive Distance
                    </td>
                  </tr>
                  <tr>
                    {driveDistance.slice(0, 9).map((value, index) => (
                      <td key={`drive-${index}`}>
                        <Input
                          type="number"
                          value={value}
                          onChange={(e) =>
                            handleDriveDistanceChange(index, e.target.value)
                          }
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td
                      style={{ background: "darkgray", color: "white" }}
                      colSpan="9"
                    >
                      Putts
                    </td>
                  </tr>
                  <tr>
                    {putts.slice(0, 9).map((value, index) => (
                      <td key={`putts-${index}`}>
                        <Input
                          type="number"
                          value={value}
                          onChange={(e) =>
                            handlePuttsChange(index, e.target.value)
                          }
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td
                      style={{ background: "darkgray", color: "white" }}
                      colSpan="9"
                    >
                      Proximity to Hole
                    </td>
                  </tr>
                  <tr>
                    {proximityToHole.slice(0, 9).map((value, index) => (
                      <td key={`proximity-${index}`}>
                        <Input
                          type="number"
                          value={value}
                          onChange={(e) =>
                            handleProximityChange(index, e.target.value)
                          }
                        />
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>
              <Table>
                <thead>
                  <tr>
                    <th
                      style={{ background: "darkgray", color: "white" }}
                      colSpan="9"
                    >
                      Hole Number
                    </th>
                  </tr>
                  <tr>
                    {Array.from({ length: 9 }, (_, i) => (
                      <th key={i + 9}>{i + 10}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{ background: "darkgray", color: "white" }}
                      colSpan="9"
                    >
                      Hole Pars
                    </td>
                  </tr>
                  <tr>
                    {holePars.slice(9, 18).map((par, index) => (
                      <td key={`par-${index + 9}`}>
                        <Input
                          type="number"
                          value={par}
                          onChange={(e) =>
                            handleHoleParChange(index + 9, e.target.value)
                          }
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td
                      style={{ background: "darkgray", color: "white" }}
                      colSpan="9"
                    >
                      Hole Scores
                    </td>
                  </tr>
                  <tr>
                    {holeScores.slice(9, 18).map((score, index) => (
                      <td key={`score-${index + 9}`}>
                        <Input
                          type="number"
                          value={score}
                          onChange={(e) =>
                            handleHoleScoreChange(index + 9, e.target.value)
                          }
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td
                      style={{ background: "darkgray", color: "white" }}
                      colSpan="9"
                    >
                      GIR
                    </td>
                  </tr>
                  <tr>
                    {gir.slice(9, 18).map((value, index) => (
                      <td key={`gir-${index + 9}`}>
                        <Input
                          type="checkbox"
                          checked={value}
                          onChange={() => handleGIRChange(index + 9)}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td
                      style={{ background: "darkgray", color: "white" }}
                      colSpan="9"
                    >
                      FIR
                    </td>
                  </tr>
                  <tr>
                    {fir.slice(9, 18).map((value, index) => (
                      <td key={`fir-${index + 9}`}>
                        <Input
                          type="checkbox"
                          checked={value}
                          onChange={() => handleFIRChange(index + 9)}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td
                      style={{ background: "darkgray", color: "white" }}
                      colSpan="9"
                    >
                      Drive Distance
                    </td>
                  </tr>
                  <tr>
                    {driveDistance.slice(9, 18).map((value, index) => (
                      <td key={`drive-${index + 9}`}>
                        <Input
                          type="number"
                          value={value}
                          onChange={(e) =>
                            handleDriveDistanceChange(index + 9, e.target.value)
                          }
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td
                      style={{ background: "darkgray", color: "white" }}
                      colSpan="9"
                    >
                      Putts
                    </td>
                  </tr>
                  <tr>
                    {putts.slice(9, 18).map((value, index) => (
                      <td key={`putts-${index + 9}`}>
                        <Input
                          type="number"
                          value={value}
                          onChange={(e) =>
                            handlePuttsChange(index + 9, e.target.value)
                          }
                        />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td
                      style={{ background: "darkgray", color: "white" }}
                      colSpan="9"
                    >
                      Proximity to Hole
                    </td>
                  </tr>
                  <tr>
                    {proximityToHole.slice(9, 18).map((value, index) => (
                      <td key={`proximity-${index + 9}`}>
                        <Input
                          type="number"
                          value={value}
                          onChange={(e) =>
                            handleProximityChange(index + 9, e.target.value)
                          }
                        />
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>
            </TableContainer>
            <p>Total Score: {calculateTotalScore()}</p>
            <Button type="submit">
              {editingId ? "Update Score" : "Add Score"}
            </Button>
            {editingId && (
              <Button type="button" onClick={() => setEditingId(null)}>
                Cancel Edit
              </Button>
            )}
          </form>
          <h3>Existing Scores</h3>
          <TableContainer>
            <Table>
              <thead>
                <tr>
                  <th>Course Name</th>
                  <th>Event Name</th>
                  <th>Date</th>
                  <th>Total Score</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {scores.map((score) => (
                  <tr key={score.id}>
                    <td>{score.courseName}</td>
                    <td>{score.eventName}</td>
                    <td>{score.date}</td>
                    <td>{score.totalScore}</td>
                    <td>
                      <Button
                        style={{ margin: "10px" }}
                        onClick={() => handleEdit(score)}
                      >
                        Edit
                      </Button>

                      <Button
                        style={{ margin: "10px" }}
                        onClick={() => handleDelete(score.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>

          <TrainingCalendar />
        </>
      )}
    </DashboardContainer>
  );
};

export default AdminDashboard;
