import React from "react";
import styled from "styled-components";
import useGlobalAnimations from "./useGlobalAnimations";

const BagContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 40px;
  justify-items: center;
  align-items: start;
  background-color: white;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 20px;
  }
`;

const EquipmentTitle = styled.h1`
  color: #ffffff;
  font-size: 2rem;
  text-align: left;
  font-weight: 700;
  margin: 0;
  padding: 30px;
  background: linear-gradient(90deg, #0000c8 0%, #ff0000 100%);
  text-transform: uppercase;
  letter-spacing: 2px;

  @media (min-width: 1080px) {
    padding-left: 15vw;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
    text-align: center;
  }
`;

const EquipmentCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const BagImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  ${EquipmentCard}:hover & {
    transform: scale(1.05);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 180px;
    height: 180px;
  }
`;

const BagTitle = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  color: #333;
  text-align: center;
  margin: 20px 0 10px;
`;

const BagText = styled.p`
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  color: #666;
  text-align: center;
  margin: 5px 0;
  max-width: 250px;
`;
function Bag() {
  useGlobalAnimations();
  const data = [
    {
      title: "Driver",
      image: "ping-g430-10k-max-golf-driver-hero.jpg",
      head: "PING G430 9 degree MAX Driver",
      shaft: "Fujikjura Vista PRO 2021 55 Stiff",
      grip: "Golf Pride MCC Teams Grey/Red",
    },
    {
      title: "Hybrid",
      image: "pingg430hybridmain.jpg",
      head: "PING G430 3-19",
      shaft: "Accra FX Gen 3 - 200H M4",
      grip: "Golf Pride MCC Teams Grey/Red",
    },
    {
      title: "Irons",
      image: "Mizuno_Pro_223_Golf_Irons_Steel_5_1800x1800.webp",
      head: "Mizuno Pro 223 4-PW",
      shaft: "Accra iSeries Steel Green 115g ",
      grip: "Golf Pride MCC Teams Blue/Red",
    },
    {
      title: "Wedges",
      image: "Titleist_Vokey_SM9_Golf_Wedge_Jet_Black_57_1080x.webp",
      head: "Titleist Vokey SM9 Jet Black 52/58",
      shaft: "Accra FX Gen 3 - 200H M4",
      grip: "Golf Pride MCC Teams Blue/Red",
    },
    {
      title: "Putter",
      image: "lab_link.jpeg",
      head: "L.A.B Link 1",
      shaft: "L.A.B Stability",
      grip: "L.A.B Press Grip",
    },
    { title: "Ball", image: "prov1.jpeg", head: "Titleist PRO V1" },
  ];

  return (
    <>
      <EquipmentTitle>My Equipment</EquipmentTitle>
      <BagContainer>
        {data.map((item) => (
          <EquipmentCard key={item.title}>
            <BagImage src={item.image} alt={item.title} />
            <BagTitle>{item.title}</BagTitle>
            <BagText>
              <strong>{item.head}</strong>
            </BagText>
            {item.shaft && <BagText>{item.shaft}</BagText>}
            {item.grip && <BagText>{item.grip}</BagText>}
          </EquipmentCard>
        ))}
      </BagContainer>
    </>
  );
}

export default Bag;
