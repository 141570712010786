import { useEffect, useCallback } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Register ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

const useGlobalAnimations = () => {
  const animateElements = useCallback(() => {
    // Use GSAP's matchMedia for responsive animations
    const mm = gsap.matchMedia();

    mm.add("(min-width: 1px)", () => {
      // General elements animation
      gsap.utils.toArray("h1, h2").forEach((element) => {
        gsap.fromTo(
          element,
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            ease: "power2.out",
            scrollTrigger: {
              trigger: element,
              start: "top 90%",
              toggleActions: "play none none reverse",
            },
          }
        );
      });

      // Social media icons animation
      gsap.utils.toArray(".social-icon").forEach((element, index) => {
        gsap.fromTo(
          element,
          { opacity: 0, x: 50 },
          {
            opacity: 1,
            x: 0,
            duration: 0.8,
            ease: "back.out(1.7)",
            scrollTrigger: {
              trigger: element,
              start: "top 90%",
              toggleActions: "play none none reverse",
            },
            delay: index * 0.1,
          }
        );
      });
    });

    // Animate elements that are already in view on page load
    gsap.utils.toArray("h1, h2, .social-icon").forEach((element) => {
      if (element.getBoundingClientRect().top < window.innerHeight) {
        gsap.to(element, {
          opacity: 1,
          y: 0,
          x: 0,
          duration: 1,
          ease: "power2.out",
        });
      }
    });
  }, []);

  useEffect(() => {
    // Small delay to ensure DOM is fully rendered
    const timer = setTimeout(() => {
      animateElements();
    }, 100);

    // Cleanup function
    return () => {
      clearTimeout(timer);
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, [animateElements]);

  return null;
};

export default useGlobalAnimations;
