import React, { lazy, Suspense, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import LoadingScreen from "./components/LoadingScreen";
import "./App.css";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Bag from "./components/Bag";
import Stat from "./components/Stat";
import Stats from "./components/Stats";
import Sponsors from "./components/Sponsors";
import HeroHeader from "./components/HeroHeader";
import Gallery from "./components/Gallery";
import TwitterFeed from "./components/TwitterFeed";
import Tournaments from "./components/Tournaments";
import AdminDashboard from "./components/AdminDashboard";
import useGlobalAnimations from "./components/useGlobalAnimations";
import Login from "./components/Login";
import HandicapTracker from "./components/HandicapTracker";
import NewsSlider from "./components/NewsSlider";
import InstagramCarousel from "./components/InstagramCarousel";

const Scores = lazy(() => import("./components/Scores"));

function App() {
  useGlobalAnimations();
  const [scores, setScores] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fadeOutLoading, setFadeOutLoading] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log("Auth state changed in App:", user);
      setUser(user);
      setIsAuthenticated(!!user);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleLoad = () => setFadeOutLoading(true);
    window.addEventListener("load", handleLoad);

    const loadTimeout = setTimeout(() => {
      setFadeOutLoading(true);
    }, 5000);

    return () => {
      window.removeEventListener("load", handleLoad);
      clearTimeout(loadTimeout);
    };
  }, []);

  useEffect(() => {
    if (fadeOutLoading) {
      setTimeout(() => setIsLoading(false), 1000);
    }
  }, [fadeOutLoading]);

  const addScore = (newScore) => {
    setScores([...scores, newScore]);
  };

  const ProtectedRoute = ({ children }) => {
    if (isLoading) {
      return <LoadingScreen fadeOut={fadeOutLoading} />;
    }
    return isAuthenticated ? children : <Navigate to="/login" />;
  };

  if (isLoading) {
    return <LoadingScreen fadeOut={fadeOutLoading} />;
  }

  return (
    <Router>
      <Suspense fallback={<LoadingScreen fadeOut={fadeOutLoading} />}>
        <div style={{ display: isLoading ? "none" : "block" }}>
          <Navbar isAuthenticated={isAuthenticated} />
          {error && <div className="error">{error}</div>}
          <Routes>
            <Route
              path="/login"
              element={
                isAuthenticated ? (
                  <Navigate to="/admin" />
                ) : (
                  <Login setIsAuthenticated={setIsAuthenticated} />
                )
              }
            />
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <AdminDashboard
                    user={user}
                    scores={scores}
                    setScores={setScores}
                    addScore={addScore}
                    setIsAuthenticated={setIsAuthenticated}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/"
              element={
                <>
                  <HeroHeader />
                  <Gallery />
                  <About />
                  <NewsSlider />
                  <InstagramCarousel />
                  {/* <TwitterFeed /> */}
                  <Bag />
                  <HandicapTracker />
                  <Scores scores={scores} />
                  <Sponsors />
                  <Tournaments />
                  <Stat />
                  {/* <Stats /> */}
                </>
              }
            />
          </Routes>
        </div>
      </Suspense>
    </Router>
  );
}

export default App;
