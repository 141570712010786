import React, { useEffect, useState, useRef, useCallback } from "react";
import styled from "styled-components";
import client from "./contentful"; // Ensure this path is correct

const Header = styled.h1`
  font-size: 1.5rem;
  text-align: left;
  font-weight: bold;
  padding: 40px;
  color: black;
`;

const NewsSliderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 200, 1) 0%,
    rgba(255, 0, 0, 1) 100%
  );
  overflow: hidden;
  position: relative;
  transition: height 0.5s ease-in-out;
`;

const NewsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px;
  margin: 0 10px;
  border-radius: 10px;
  max-width: 75%;
  color: #fff;
  position: absolute;
  transition: opacity 1s ease-in-out;
  width: 100%;
  z-index: ${(props) => (props.active ? 1 : -1)};
  opacity: ${(props) => (props.active ? 1 : 0)};
`;

const NewsHeader = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: left;
`;

const NewsText = styled.p`
  font-size: 1rem;
  margin-bottom: 10px;
  text-align: left;
`;

const NewsLink = styled.a`
  font-size: 1rem;
  color: lightgreen;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 2;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

const PrevButton = styled(Button)`
  left: 10px;
`;

const NextButton = styled(Button)`
  right: 10px;
`;

const LoadingMessage = styled.div`
  color: white;
  font-size: 1.5rem;
`;

const NewsSlider = () => {
  const [news, setNews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const containerRef = useRef(null);
  const itemRefs = useRef([]);

  const fetchNews = useCallback(async () => {
    try {
      const response = await client.getEntries({
        content_type: "news",
        order: "-sys.createdAt",
        limit: 5,
      });
      setNews(response.items);
      itemRefs.current = response.items.map(() => React.createRef());
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching news:", error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchNews();
  }, [fetchNews]);

  useEffect(() => {
    if (!isPaused && !isLoading) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % news.length);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [news.length, isPaused, isLoading]);

  useEffect(() => {
    const updateContainerHeight = () => {
      if (
        itemRefs.current[currentIndex] &&
        itemRefs.current[currentIndex].current
      ) {
        const activeItemHeight =
          itemRefs.current[currentIndex].current.clientHeight;
        containerRef.current.style.height = `${activeItemHeight}px`;
      }
    };

    updateContainerHeight();
    window.addEventListener("resize", updateContainerHeight);

    return () => window.removeEventListener("resize", updateContainerHeight);
  }, [currentIndex, news]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? news.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % news.length);
  };

  const handleMouseEnter = () => setIsPaused(true);
  const handleMouseLeave = () => setIsPaused(false);

  if (isLoading) {
    return (
      <NewsSliderContainer ref={containerRef}>
        <LoadingMessage>Loading news...</LoadingMessage>
      </NewsSliderContainer>
    );
  }

  return (
    <>
      <Header>LATEST NEWS</Header>
      <NewsSliderContainer ref={containerRef}>
        <PrevButton onClick={handlePrev} aria-label="Previous news item">
          &lt;
        </PrevButton>
        {news.map((newsItem, index) => (
          <NewsItem
            key={newsItem.sys.id}
            ref={itemRefs.current[index]}
            active={index === currentIndex}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <NewsHeader>{newsItem.fields.newsHeading}</NewsHeader>
            <NewsText>{newsItem.fields.newsText}</NewsText>
            {newsItem.fields.newsLink && (
              <NewsLink
                href={newsItem.fields.newsLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Read more
              </NewsLink>
            )}
          </NewsItem>
        ))}
        <NextButton onClick={handleNext} aria-label="Next news item">
          &gt;
        </NextButton>
      </NewsSliderContainer>
    </>
  );
};

export default NewsSlider;
