// Desc: Sponsors page
import React, { useEffect, useState } from "react";
import client from "./contentful";
import styled from "styled-components";

const SponsorsTitle = styled.h1`
  color: #ffffff;
  font-size: 1rem;
  text-align: left;
  font-weight: 700;

  padding: 20px;
  width: 100vw;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 200, 1) 0%,
    rgba(255, 0, 0, 1) 100%
  );

  @media (min-width: 1080px) {
    text-align: left;
    padding-left: 15vw;
  }
`;

const Container = styled.div`
  display: flex;
  background-color: #222;
  align-items: center;
  justify-content: center;
  height: 30vh;
  padding: 20px; // Optional padding

  @media (max-width: 1100px) and (min-width: 769px) {
    flex-wrap: wrap;
    height: auto; // Adjust the height to accommodate two rows
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(
      auto-fit,
      minmax(150px, 1fr)
    ); // Adjust minmax values as needed
    grid-gap: 20px; // Adjust the space between logos

    height: 40vh;
    padding: 50px;
    display: flex;
    flex-wrap: wrap;
    div {
      animation: none;
    }
  }

  @media (max-width: 480px) {
    height: auto;
  }
`;
const Png = styled.img`
  max-width: 250px;
  max-height: 250px;
  min-width: 10vmin;
  filter: grayscale(100%); // Apply grayscale filter
  transition: filter 0.3s ease;
  padding: 1rem;

  &:hover {
    filter: none; // Remove grayscale on hover
    transform: scale(1.2);
    transition: 0.5s ease;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

function Sponsors() {
  const [logos, setLogos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.getEntries({
          content_type: "sponsors",
        });

        console.log("Contentful response:", response); // Log the response to inspect structure

        const fetchedLogos = response.items.map((item) => ({
          title: item.fields.sponsorName,
          link: item.fields.sponsorLink,
          image: item.fields.sponsorImage.fields.file.url, // Correctly access the image URL
        }));

        setLogos(fetchedLogos);
      } catch (error) {
        console.error("Error fetching Sponsors:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <SponsorsTitle>MY SPONSORS</SponsorsTitle>
      <Container>
        {logos.map((logo, index) => (
          <a
            key={index}
            href={logo.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Png src={logo.image} alt={logo.title} />
          </a>
        ))}
      </Container>
    </>
  );
}

export default Sponsors;
