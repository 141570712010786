// src/components/Stats.js
import React from "react";
import styled from "styled-components";
import useStats from "../hooks/useStats";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const StatTitle = styled.h1`
  color: #ffffff;
  font-size: 1rem;
  text-align: left;
  font-weight: 700;

  padding: 20px;
  width: 100vw;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 200, 1) 0%,
    rgba(255, 0, 0, 1) 100%
  );

  @media (min-width: 1080px) {
    text-align: left;
    padding-left: 15vw;
  }
`;

const StatContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: black;
  height: auto;
  padding: 20px 20px;
`;

const StatBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1));
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px); /* For Safari */
  padding: 20px;
  margin: 20px;
  width: 200px;
  height: 200px;
  color: white;
  flex: 1 1 calc(25% - 40px); /* Adjusts the width to ensure four items per row */

  &:hover {
    background-color: rgba(32, 43, 60, 0.8);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transform: scale(1.05);
    transition: 0.8s ease-in-out;
  }

  @media (max-width: 768px) {
    height: 350px;
    flex: 1 1 30%;
  }

  @media (max-width: 480px) {
    height:150px;
    flex: 1 1 40%;
  }
}
`;

const MetricTitle = styled.h3`
  color: lightgray;
  font-size: 1rem;
  text-align: center;
  font-weight: 700;
  width: 100%;
`;

// const colorPalette = ["#FF9980", "#80FF99", "#8099FF", "#FF80FF"];

const MetricValue = styled.p`
  color: offwhite;
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
  width: 100%;
`;
function Stat() {
  const stats = useStats();

  return (
    <>
      <StatTitle>KEY STATS</StatTitle>
      <StatContainer>
        <StatBox>
          <MetricTitle>Scoring Avg. Par 3</MetricTitle>
          <MetricValue>{stats.scoringAveragePar3}</MetricValue>
        </StatBox>
        <StatBox>
          <MetricTitle>Scoring Avg. Par 4</MetricTitle>
          <MetricValue>{stats.scoringAveragePar4}</MetricValue>
        </StatBox>
        <StatBox>
          <MetricTitle>Scoring Avg. Par 5</MetricTitle>
          <MetricValue>{stats.scoringAveragePar5}</MetricValue>
        </StatBox>
        <StatBox>
          <MetricTitle>GIR %</MetricTitle>
          <CircularProgressbar
            value={stats.girPercentage}
            text={`${stats.girPercentage}`}
            styles={buildStyles({
              pathColor: `rgba(255, 165, 255, ${stats.girPercentage / 100})`,
              textColor: "#ffffff",
              trailColor: "none",
              backgroundColor: "#3e98c7",
            })}
          />
        </StatBox>
        <StatBox>
          <MetricTitle>FIR %</MetricTitle>
          <CircularProgressbar
            value={stats.firPercentage}
            text={`${stats.firPercentage}`}
            styles={buildStyles({
              pathColor: `rgba(62, 152, 100, ${stats.firPercentage / 100})`,
              textColor: "#ffffff",
              trailColor: "none",
              backgroundColor: "#3e98c7",
            })}
          />
        </StatBox>
        <StatBox>
          <MetricTitle>Avg. Drive Distanc (yds)</MetricTitle>
          <MetricValue>{stats.averageDriveDistance}</MetricValue>
        </StatBox>
        <StatBox>
          <MetricTitle>Avg. Putts</MetricTitle>
          <MetricValue>{stats.averagePutts}</MetricValue>
        </StatBox>
        <StatBox>
          <MetricTitle>Avg. Proximity to Hole (ft)</MetricTitle>
          <MetricValue>{stats.averageProximityToHole}</MetricValue>
        </StatBox>
        <StatBox>
          <MetricTitle>Avg. Putt Length Holed (ft)</MetricTitle>
          <MetricValue>{stats.averagePuttLengthHoled}</MetricValue>
        </StatBox>
      </StatContainer>
    </>
  );
}

export default Stat;
