import { Timeline } from "react-twitter-widgets";
import styled from "styled-components";
import { useEffect, useState } from "react";

const TwitterFeedContainer = styled.div`
  display: flex;
  padding-bottom: 10vh;
  padding-top: 10vh;
  justify-content: center;
  align-items: center;
`;

export const TwitterFeed = () => {
  const [dimensions, setDimensions] = useState({
    width: "75vw",
    height: "auto",
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        setDimensions({ width: "90vw", height: "400px" });
      } else if (window.innerWidth < 768) {
        setDimensions({ width: "90vw", height: "auto" });
      } else if (window.innerWidth < 1100) {
        setDimensions({ width: "90vw", height: "1000px" });
      } else {
        setDimensions({ width: "50vw", height: "1000px" });
      }
    };

    window.addEventListener("resize", handleResize);

    // Initial call to set the correct dimensions
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <TwitterFeedContainer>
      <Timeline
        dataSource={{ sourceType: "profile", screenName: "CameronLoweGolf" }}
        options={dimensions}
      />
    </TwitterFeedContainer>
  );
};

export default TwitterFeed;
