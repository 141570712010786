import React from "react";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
`;

const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #202b3c;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: ${fadeIn} 0.5s ease-in-out,
    ${(props) => props.fadeOut && fadeOut} 1s ease-in-out;
  animation-fill-mode: forwards;
`;

const Letter = styled.span`
  font-size: 5rem;
  color: white;
  margin: 0 5px;
  animation: ${bounce} 2s infinite;
  animation-delay: ${(props) => props.delay}s;
`;

const LoadingScreen = ({ fadeOut }) => {
  return (
    <LoadingOverlay fadeOut={fadeOut}>
      <Letter delay={0}>C</Letter>
      <Letter delay={0.2}>L</Letter>
      <Letter delay={0.4}>G</Letter>
    </LoadingOverlay>
  );
};

export default LoadingScreen;
