import { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

const useStats = () => {
  const [stats, setStats] = useState({
    scoringAveragePar3: "-",
    scoringAveragePar4: "-",
    scoringAveragePar5: "-",
    totalScore: 0,
    averageScore: 0,
    girPercentage: 0,
    firPercentage: 0,
    averageDriveDistance: 0,
    averagePutts: 0,
    averageProximityToHole: 0,
    averagePuttLengthHoled: 0,
  });

  useEffect(() => {
    const calculateStats = (scores) => {
      let totalScore = 0;
      let totalRounds = scores.length;

      let totalPar3 = 0,
        totalPar4 = 0,
        totalPar5 = 0;
      let countPar3 = 0,
        countPar4 = 0,
        countPar5 = 0;

      let totalGIR = 0;
      let totalFIR = 0;
      let totalDriveDistance = 0;
      let totalPutts = 0;
      let totalProximityToHole = 0;

      let girCount = 0;
      let firCount = 0;
      let driveDistanceCount = 0;
      let puttsCount = 0;
      let proximityCount = 0;

      scores.forEach((score) => {
        totalScore += score.totalScore;

        score.gir.forEach((gir) => {
          if (gir !== "") {
            totalGIR += gir;
            girCount++;
          }
        });

        score.fir.forEach((fir, index) => {
          if (fir !== "" && score.holePars[index] !== 3) {
            totalFIR += fir;
            firCount++;
          }
        });

        score.driveDistance.forEach((distance) => {
          if (distance !== "" && distance !== 0) {
            totalDriveDistance += distance;
            driveDistanceCount++;
          }
        });

        score.putts.forEach((putt) => {
          if (putt !== "") {
            totalPutts += putt;
            puttsCount++;
          }
        });

        score.proximityToHole.forEach((proximity) => {
          if (proximity !== "") {
            totalProximityToHole += proximity;
            proximityCount++;
          }
        });

        score.holePars.forEach((par, index) => {
          if (par === 3) {
            totalPar3 += score.holeScores[index];
            countPar3++;
          } else if (par === 4) {
            totalPar4 += score.holeScores[index];
            countPar4++;
          } else if (par === 5) {
            totalPar5 += score.holeScores[index];
            countPar5++;
          }
        });
      });

      const averageProximityToHole =
        proximityCount > 0 ? totalProximityToHole / proximityCount : 0;
      const averagePutts = puttsCount > 0 ? totalPutts / puttsCount : 0;
      const averagePuttLengthHoled =
        averagePutts > 0 ? averageProximityToHole / averagePutts : 0;

      const formatScoringAverage = (value, par) => {
        if (value === "-") return "-";
        const score = (value - par).toFixed(2);
        return score > 0 ? `+${score}` : `${score}`;
      };

      setStats({
        totalScore,
        averageScore: totalRounds > 0 ? totalScore / totalRounds : 0,
        scoringAveragePar3:
          countPar3 > 0 ? formatScoringAverage(totalPar3 / countPar3, 3) : "-",
        scoringAveragePar4:
          countPar4 > 0 ? formatScoringAverage(totalPar4 / countPar4, 4) : "-",
        scoringAveragePar5:
          countPar5 > 0 ? formatScoringAverage(totalPar5 / countPar5, 5) : "-",
        girPercentage:
          girCount > 0 ? ((totalGIR / girCount) * 100).toFixed(2) : 0,
        firPercentage:
          firCount > 0 ? ((totalFIR / firCount) * 100).toFixed(2) : 0,
        averageDriveDistance:
          driveDistanceCount > 0
            ? (totalDriveDistance / driveDistanceCount).toFixed(2)
            : 0,
        averagePutts: averagePutts.toFixed(2),
        averageProximityToHole: averageProximityToHole.toFixed(2),
        averagePuttLengthHoled: averagePuttLengthHoled.toFixed(2),
      });
    };

    const fetchStats = async () => {
      try {
        const today = "2024-06-18"; // Using the fixed date

        const q = query(collection(db, "scores"), where("date", ">=", today));
        const snapshot = await getDocs(q);
        const scoresList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        calculateStats(scoresList);
      } catch (error) {
        console.error("Error fetching scores:", error);
      }
    };

    fetchStats();
  }, []);

  return stats;
};

export default useStats;
