import React, { useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import client from "./contentful"; // Ensure this path is correct
import InfiniteScroll from "react-infinite-scroll-component";

// Global styles to prevent horizontal overflow
const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal overflow */
    width: 100vw;
  }
`;

// Styles for the gallery container
const GalleryContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  align-items: center;
  padding-top: 20px;
  height: 30vh;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 200, 1) 0%,
    rgba(255, 0, 0, 1) 100%
  );
  overflow: hidden; /* Prevent horizontal overflow */

  @media (max-width: 768px) {
    height: auto;
    width: 100vw;
    padding-bottom: 20px;
  }
`;

// Styles for the images
const Image = styled.img`
  height: 25vmin;
  max-width: 90vw;
  margin: 0 0.5rem;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  &:hover {
    transform: scale(1.1);
    transition: transform 0.2s;
    border-radius: 5px;
  }

  @media (max-width: 768px) {
    height: 50vmin;
    max-width: 90vw;
    margin: 0 1rem;
  }
`;

// Wrapper for images with infinite scroll
const ImagesWrapper = styled.div`
  height: 30vmin;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  overflow-x: auto; /* Allow horizontal scrolling */
  scroll-behavior: smooth;
  white-space: nowrap;
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for webkit browsers */
  }

  @media (max-width: 768px) {
    height: 60vmin;
    max-width: 100vw;
    padding: 2rem;
    margin-top: 0;
  }
`;

const Loading = styled.div`
  text-align: center;
  padding: 20px;
  color: #ffffff;
`;

const fetchImages = async (skip) => {
  const response = await client.getEntries({
    content_type: "galleryImage",
    limit: 10,
    skip,
  });
  return response.items;
};

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    loadMoreImages();
  }, []);

  const loadMoreImages = async () => {
    const newImages = await fetchImages(skip);
    const imageUrls = newImages
      .map((item) => {
        if (
          item.fields &&
          item.fields.galleryImage &&
          item.fields.galleryImage.fields &&
          item.fields.galleryImage.fields.file
        ) {
          return item.fields.galleryImage.fields.file.url;
        } else {
          console.error("Missing fields in item:", item);
          return null;
        }
      })
      .filter((url) => url !== null);

    setImages((prevImages) => [...prevImages, ...imageUrls]);
    setSkip((prevSkip) => prevSkip + 10);
    if (newImages.length < 10) {
      setHasMore(false);
    }
  };

  return (
    <>
      <GlobalStyle />
      <InfiniteScroll
        dataLength={images.length}
        next={loadMoreImages}
        hasMore={hasMore}
        scrollableTarget="scrollableDiv"
      >
        <GalleryContainer id="scrollableDiv">
          <ImagesWrapper>
            {images.map((image, index) => {
              return (
                <Image
                  key={index}
                  src={image}
                  alt={`Gallery Image ${index + 1}`}
                />
              );
            })}
          </ImagesWrapper>
        </GalleryContainer>
      </InfiniteScroll>
    </>
  );
};

export default Gallery;
