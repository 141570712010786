import React from "react";
import styled from "styled-components";

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2rem;
  position: sticky;
  top: 0;
  height: 100px;
  background-color: #222;
  z-index: 10;
`;

const Logo = styled.div`
  font-size: 1.5rem;
  color: #fff;
  font-family: "Montserrat", sans-serif; // Example font
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 1rem;
  margin-right: 1rem;

  @media (max-width: 480px) {
    margin-right: 0.5rem;
  }
`;

const NavLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  &:hover {
    text-decoration: underline;
  }
`;

const EmailIcon = styled.svg`
  width: 24px;
  height: 24px;
  fill: currentColor;
`;

const Navbar = () => {
  return (
    <HeaderContainer>
      <Logo to="/">CLG</Logo>
      <NavLinks>
        <NavLink href="mailto:contact@cameronlowegolf.com">
          <EmailIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
          </EmailIcon>
        </NavLink>
      </NavLinks>
    </HeaderContainer>
  );
};

export default Navbar;
