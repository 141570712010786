import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

import YouTubeIcon from "@mui/icons-material/YouTube";
import useGlobalAnimations from "./useGlobalAnimations";

const SocialsContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100vw;
  height: auto;
  background: white;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SocialsHeader = styled.h1`
  font-size: 1.5rem;

  text-align: center;
  font-weight: bold;
  padding: 20px;
`;

const SocialBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0 10px;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    
    
`;

const SocialMediaLink = styled.a`
  color: inherit;
  text-decoration: none;
  opacity: 1;
  transform: translateX(100px);

  &:hover {
    color: #4a6fa5;
  }

  @media (max-width: 768px) {
    transform: translatex(0px);
`;

const InstagramFeedContainer = styled.div`
  width: 100vw;
  margin: auto;
  padding: 20px;
  background: linear-gradient(
    90deg,
    rgba(155, 0, 155, 1) 0%,
    rgba(255, 0, 0, 1) 100%
  );
`;

const InstagramPost = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 600px;
  background: white;

  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
`;

const MediaContainer = styled.div`
  max-width: 100%;
  max-height: 100%;
  img,
  video {
    width: 100%;
    height: 100%;
    max-height: 400px;
    object-fit: cover;
  }
`;

const Caption = styled.div`
  padding: 10px 15px;
  color: #333;
  font-size: 0.9rem;
  line-height: 1.4;

  @media (max-width: 768px) {
  }
`;

const Username = styled.div`
  font-weight: bold;
  color: #000;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const CarouselContainer = styled.div`
  .slick-slide {
    padding: 10px;
  }
`;

const InstagramCarousel = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  useGlobalAnimations();

  const fetchInstagramPosts = useCallback(async () => {
    const accessToken = process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN;

    if (!accessToken) {
      setError("Access token is missing");
      return;
    }

    try {
      const response = await axios.get(`https://graph.instagram.com/me/media`, {
        params: {
          fields: "id,caption,media_type,media_url,permalink,username",
          access_token: accessToken,
        },
      });
      setPosts(response.data.data);
    } catch (error) {
      console.error("Error fetching Instagram posts:", error);
      setError(
        error.response?.data?.error?.message ||
          "Failed to fetch Instagram posts"
      );
    }
  }, []);

  useEffect(() => {
    fetchInstagramPosts();
  }, [fetchInstagramPosts]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <SocialsContainer>
        <SocialsHeader>FOLLOW ME ON SOCIAL MEDIA</SocialsHeader>
        <SocialBlock>
          <SocialMediaLink
            href="https://www.instagram.com/camlowegolf/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon className="social-icon" style={{ fontSize: 40 }} />
          </SocialMediaLink>
          <SocialMediaLink
            href="https://twitter.com/cameronlowegolf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon className="social-icon" style={{ fontSize: 40 }} />
          </SocialMediaLink>
          <SocialMediaLink
            href="https://www.youtube.com/channel/UC1QY6uVH3w5Z0t8xHJxXs1Q"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YouTubeIcon className="social-icon" style={{ fontSize: 40 }} />
          </SocialMediaLink>
        </SocialBlock>
      </SocialsContainer>
      <InstagramFeedContainer>
        <CarouselContainer>
          <Slider {...settings}>
            {posts.map((post) => (
              <InstagramPost key={post.id}>
                <MediaContainer>
                  {post.media_type === "IMAGE" && (
                    <a
                      href={post.permalink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={post.media_url} alt={post.caption} />
                    </a>
                  )}
                  {post.media_type === "VIDEO" && (
                    <a
                      href={post.permalink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <video controls>
                        <source src={post.media_url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </a>
                  )}
                  {post.media_type === "CAROUSEL_ALBUM" && (
                    <a
                      href={post.permalink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={post.media_url} alt={post.caption} />
                    </a>
                  )}
                </MediaContainer>
                <Caption>
                  <Username>{post.username}</Username>
                  {post.caption}
                </Caption>
              </InstagramPost>
            ))}
          </Slider>
        </CarouselContainer>
      </InstagramFeedContainer>
    </>
  );
};

export default InstagramCarousel;
