import React, { useMemo } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import useHandicapData from "../hooks/useHandicapData";
import { linearRegression, linearRegressionLine } from "simple-statistics";

const HandicapTrackerContainer = styled.div`
  padding: 10px;
  background-color: #f5f5f5;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 768px) {
    padding: 20px;
  }
`;

const Title = styled.h2`
  color: #333;
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.5rem;

  @media (min-width: 768px) {
    font-size: 2rem;
    margin-bottom: 20px;
  }
`;

const ChartContainer = styled.div`
  height: 300px;
  width: 100%;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    height: 400px;
    margin-bottom: 20px;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
    gap: 20px;
  }
`;

const StatBox = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;

  @media (min-width: 768px) {
    flex: 1;
    min-width: 200px;
    padding: 15px;
  }
`;

const StatTitle = styled.h3`
  font-size: 1rem;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

const StatValue = styled.p`
  font-size: 1.2rem;
  font-weight: bold;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const HandicapTracker = () => {
  const { handicapData } = useHandicapData();

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const chartData = useMemo(() => {
    if (handicapData.length === 0) return [];

    const sortedData = [...handicapData].sort((a, b) => a.date - b.date);

    // Calculate projection
    const firstDate = sortedData[0].date;
    const regressionData = sortedData.map((entry) => [
      (entry.date - firstDate) / (1000 * 60 * 60 * 24),
      entry.handicapIndex,
    ]);

    const regression = linearRegression(regressionData);
    const predict = linearRegressionLine(regression);

    const lastDate = sortedData[sortedData.length - 1].date;
    const projectedData = [];

    for (let i = 1; i <= 6; i++) {
      const projectedDate = new Date(lastDate);
      projectedDate.setMonth(projectedDate.getMonth() + i);
      const daysSinceStart =
        (projectedDate - firstDate) / (1000 * 60 * 60 * 24);
      const projectedHandicap = Math.max(predict(daysSinceStart), 0);

      projectedData.push({
        date: projectedDate,
        projectedHandicap: parseFloat(projectedHandicap.toFixed(1)),
      });
    }

    return [...sortedData, ...projectedData];
  }, [handicapData]);

  const getLatestHandicap = () => {
    if (handicapData.length === 0) return "N/A";
    const latestData = handicapData[handicapData.length - 1];
    return latestData.handicapIndex.toFixed(1);
  };

  const getProjectedHandicap = () => {
    if (chartData.length === 0) return "N/A";
    const projectedData = chartData.filter((d) => "projectedHandicap" in d);
    if (projectedData.length === 0) return "N/A";
    return projectedData[projectedData.length - 1].projectedHandicap.toFixed(1);
  };

  if (handicapData.length === 0) {
    return (
      <HandicapTrackerContainer>
        <Title>My Handicap Performance</Title>
        <p>
          No handicap data available. Please add some data to see your
          performance.
        </p>
      </HandicapTrackerContainer>
    );
  }

  return (
    <HandicapTrackerContainer>
      <Title>My Handicap Performance</Title>
      <ChartContainer>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={chartData}
            margin={{ top: 5, right: 10, left: 0, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={formatDate}
              type="category"
              allowDuplicatedCategory={false}
              angle={-45}
              textAnchor="end"
              height={50}
              interval={"preserveStartEnd"}
              tick={{ fontSize: 10 }}
            />
            <YAxis tick={{ fontSize: 10 }} />
            <Tooltip
              labelFormatter={(value) => formatDate(new Date(value))}
              formatter={(value) => value.toFixed(1)}
            />
            <Legend wrapperStyle={{ fontSize: 10 }} />
            <Line
              type="monotone"
              dataKey="handicapIndex"
              name="Handicap Index"
              stroke="#8884d8"
              strokeWidth={2}
              dot={{ r: 3 }}
            />
            <Line
              type="monotone"
              dataKey="scoreDifferential"
              name="Score Differential"
              stroke="#82ca9d"
              strokeWidth={2}
              dot={{ r: 3 }}
            />
            <Line
              type="monotone"
              dataKey="projectedHandicap"
              name="Projected Handicap"
              stroke="#ff7300"
              strokeWidth={2}
              strokeDasharray="5 5"
              dot={{ r: 3 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </ChartContainer>
      <StatsContainer>
        <StatBox>
          <StatTitle>Current Handicap Index</StatTitle>
          <StatValue>{getLatestHandicap()}</StatValue>
        </StatBox>
        <StatBox>
          <StatTitle>Projected Handicap (6 months)</StatTitle>
          <StatValue>{getProjectedHandicap()}</StatValue>
        </StatBox>
      </StatsContainer>
    </HandicapTrackerContainer>
  );
};

export default HandicapTracker;
