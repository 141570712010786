import styled from "styled-components";

const HeroHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 50vh; /* Increased height for better scroll testing */
  background: linear-gradient(
    90deg,
    rgba(0, 0, 200, 1) 0%,
    rgba(255, 0, 0, 1) 100%
  );
  justify-content: center;
  align-items: left;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 30vh;
    padding-top: 20px;
  }
`;

const MainText = styled.h1`
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  margin: 0; /* Centered within the container */
  font-size: 12vmin;
  color: white;
  text-align: Left;
  opacity: 0;
  margin-left: 20px;

  @media (max-width: 768px) {
    font-size: 14vmin;
    line-height: 1;
  }
`;

const SubHeading = styled.h2`
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  margin: 0; /* Centered within the container */
  font-size: 8vmin;
  color: white;
  text-align: left;
  opacity: 0;
  margin-left: 20px;

  @media (max-width: 768px) {
    font-size: 6vmin;
  }
`;

const HeroHeader = () => {
  return (
    <HeroHeaderContainer>
      <MainText className="main-text">CAMERON LOWE</MainText>
      <SubHeading className="sub-heading">ELITE AMATEUR GOLFER</SubHeading>
    </HeroHeaderContainer>
  );
};

export default HeroHeader;
