import React, { useEffect, useState } from "react";
import styled from "styled-components";
import client from "./contentful";

const TournamentsTitle = styled.h1`
  color: #ffffff;
  font-size: 1rem;
  text-align: left;
  font-weight: 700;
  margin-bottom: 30px;
  padding: 20px;
  width: 100vw;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 200, 1) 0%,
    rgba(255, 0, 0, 1) 100%
  );

  @media (min-width: 1080px) {
    text-align: left;
    padding-left: 15vw;
  }
`;

const TournamentsContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: auto;
  padding: 0.5rem 0;

  overflow-x: scroll;
  scroll-behavior: smooth;
  /* hide scrollbar */
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 768px) {
    height: auto;
    max-width: 100vw;
    padding: 2rem;
    margin-top: 0;
  }
`;

const TournamentCard = styled.div`
  position: relative;
  width: auto;
  height: 600px;
  border-radius: 10px;
  margin: 20px;

  &:hover {
    transform: scale(1.05);
    transition: transform 0.5s;
  }

  @media (max-width: 768px) {
    height: auto;
    margin: 1rem;
    margin-bottom: 6vh;
  }
`;

const CardImage = styled.img`
  max-width: 75vw;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;

  @media (max-width: 768px) {
    height: 400px;
    width: auto;
  }
`;

const CardInfo = styled.div`
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  padding-left: 30px;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  border-radius: 10px;

  @media (max-width: 768px) {
    font-size: 2.5vmin;
  }
`;

function Tournaments() {
  const [tournaments, setTournaments] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.getEntries({
          content_type: "event",
        });

        const fetchedTournaments = response.items.map((item) => ({
          title: item.fields.eventName,
          location: item.fields.eventLocation,
          date: new Date(item.fields.eventDate),
          prizefund: item.fields.eventPrizefund,
          info: item.fields.eventInfo,
          image: item.fields.eventImage.fields.file.url, // Assuming nested structure for image URL
        }));

        fetchedTournaments.sort((a, b) => a.date - b.date);

        setTournaments(fetchedTournaments);
      } catch (error) {
        console.error("Error fetching tournaments:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <TournamentsTitle>UPCOMING EVENTS</TournamentsTitle>
      <TournamentsContent>
        {tournaments.map((tournament, index) => (
          <TournamentCard key={index}>
            <CardImage src={tournament.image} alt={tournament.title} />
            <CardInfo>
              <h3>{tournament.title}</h3>
              <p>{tournament.location}</p>
              <p>{tournament.date.toDateString()}</p>
              <p>{tournament.prizefund}</p>
              <a
                href={tournament.info}
                target="_blank"
                rel="noreferrer noopener"
              >
                More Info
              </a>
            </CardInfo>
          </TournamentCard>
        ))}
      </TournamentsContent>
    </>
  );
}

export default Tournaments;
